<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-5 col-xl-4 my-5 card light-shadow p-5">
        <h1 class="display-4 text-center mb-3">Reset Password</h1>
        <form
          name="ResetPasswordForm"
          autocomplete="off"
          validate
          @submit.prevent="resetPassword"
          method="post"
        >
          <!--<input-control  v-model="email" id="email" control="email" :required="true">-->
          <!--Email Address-->
          <!--</input-control>-->
          <input-control
            v-model="password"
            id="password"
            control="password"
            :required="true"
          >
            Password
          </input-control>
          <input-control
            v-model="password_confirmation"
            id="password_confirmation"
            control="password"
            :required="true"
          >
            Confirm Password
          </input-control>
          <save
            :saving="auth.resettingPassword"
            classes="btn btn-lg btn-block btn-black mb-3"
          >
            Reset Password
          </save>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "ResetPasswordForm",
  computed: {
    ...mapState(["auth"]),
  },
  data() {
    return {
      password: null,
      password_confirmation: null,
    };
  },
  methods: {
    ...mapActions({
      requestResetPassword: "auth/resetPassword",
    }),
    async resetPassword() {
      try {
        if (this.password != this.password_confirmation) {
          Swal.fire({
            title: "Reset Password Failed",
            text: "The password confirmation does not match.",
            icon: "error",
          });
          return;
        }
        await this.requestResetPassword({
          token: this.$route.params.token,
          email: this.$route.query.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
        });
        Swal.fire({
          title: "Reset Completed",
          text: "Password Successfully Reset",
          icon: "",
        });
        this.$router.push({ name: "login" });
      } catch (err) {
        Swal.fire({
          title: "Reset Password Failed",
          text: err.message,
          icon: "error",
        });
      }
    },
  },
};
</script>
